import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

//redux
import {
  useSelector,
  // useDispatch
} from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "../../../Components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import "../styles.scss";

// action
// import { userForgetPassword } from "../../slices/thunks";

import ParticlesAuth from "../ParticlesAuth";
import { createSelector } from "reselect";
import AuthenticationHeader from "../AuthenticationHeader";
import { useTranslation } from "react-i18next";
import Icon from "../../../Components/Common/icon";
import { RecoverPassword } from "../../../infra/requests/AuthRequests";
import BaseButton from "../../../Components/Common/Buttons/BaseButton";

const ForgotPassword = ({ router }) => {
  // const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [emailWasSent, setEmailWasSent] = useState(false);
  const [hasUserNotFoundError, setHasUserNotFoundError] = useState(false);

  const { navigate } = router;

  const { t } = useTranslation();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: async ({ email }) => {
      const payload = { email };
      setIsLoading(true);
      try {
        const { success } = await RecoverPassword(payload);

        if (success) {
          setEmailWasSent(true);
          setIsLoading(false);
        } else {
          setHasUserNotFoundError(true);
          setIsLoading(false);
        }
      } catch (e) {
        console.error(e);
        setHasUserNotFoundError(true);
        setIsLoading(false);
      }
    },
  });

  const selectLayoutState = (state) => state.ForgetPassword;
  const selectLayoutProperties = createSelector(selectLayoutState, (state) => ({
    forgetError: state?.forgetError,
    forgetSuccessMsg: state?.forgetSuccessMsg,
  }));

  const handleContinueClick = () => {
    navigate("/login");
  };

  const { forgetError, forgetSuccessMsg } = useSelector(selectLayoutProperties);

  return (
    <ParticlesAuth>
      <div className="auth-page-content mt-lg-5">
        <Container>
          <AuthenticationHeader />

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5} className="login-form-card">
              <Card className="mt-2">
                {!emailWasSent ? (
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">{t("FORGOT_PASSWORD")}</h5>
                      <p className="text-muted">
                        {t("FORGOT_PASSWORD_SUBTITLE")}
                      </p>
                    </div>
                    <div className="icon-container">
                      <Icon icon="SendEmail" fill="blue" size={[95, 44]} />
                    </div>
                    <Alert
                      color={hasUserNotFoundError ? "danger" : "warning"}
                      className="border-0 text-center mb-2 mx-2"
                      role="alert"
                    >
                      {t(
                        hasUserNotFoundError
                          ? "USER_NOT_FOUND"
                          : "FORGOT_PASSWORD_ALERT_INSTRUCTIONS"
                      )}
                    </Alert>
                    <div className="p-2">
                      {forgetError && forgetError ? (
                        <Alert color="danger" className="alert-margin">
                          {forgetError}
                        </Alert>
                      ) : null}
                      {forgetSuccessMsg ? (
                        <Alert color="success" className="alert-margin">
                          {forgetSuccessMsg}
                        </Alert>
                      ) : null}
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-4">
                          <Label className="form-label">{t("EMAIL")}</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.email}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="text-center mt-4">
                          <BaseButton
                            htmlType="submit"
                            type="submit"
                            loading={isLoading}
                            disabled={isLoading}
                            text={"Recover Password"}
                            width="full"
                          >
                            {t("RECOVER_PASSWORD")}
                          </BaseButton>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                ) : (
                  <CardBody className="p-4">
                    <div className="text-center mt-5 mb-5 mb-3 d-flex flex-column align-items-center">
                      <Icon icon="EmailSent" fill="green" size={[83, 58]} />
                      <h5 className="text-success">
                        {t("VERIFICATION_EMAIL_SENT")}
                      </h5>
                      <p className="text-muted">
                        {t("VERIFICATION_EMAIL_INSTRUCTIONS")}
                      </p>
                    </div>
                    <div className="text-center mt-5">
                      <button
                        className="btn btn-info w-100"
                        type="button"
                        onClick={handleContinueClick}
                      >
                        {t("CONTINUE")}
                      </button>
                    </div>
                  </CardBody>
                )}
              </Card>

              {!emailWasSent && (
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    {t("WAIT_I_REMEMBER_PASSWORD")}{" "}
                    <Link
                      to="/login"
                      className="fw-semibold click-here text-decoration-underline"
                    >
                      {t("CLICK_HERE")}{" "}
                    </Link>{" "}
                  </p>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgotPassword);
