import { LoginAuth, LogoutAuth } from "../../../infra/requests/AuthRequests";
import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  reset_login_flag,
  start_loading,
  end_loading,
} from "./reducer";
import { getProfile } from "../profile/thunk";
import { getInfo } from "../../info/thunk";

export const loginUser = (user, history, rememberMe) => async (dispatch) => {
  try {
    dispatch(start_loading(true));
    const { success, data } = await LoginAuth(user);

    if (success) {
      dispatch(end_loading(false));
      if (rememberMe) {
        localStorage.setItem("user_email", user.email);
      } else {
        localStorage.removeItem("user_email");
      }
      localStorage.setItem("authToken", data);
      localStorage.setItem("authUser", JSON.stringify(data));
      dispatch(loginSuccess(data));
      // redirects to homepage
      dispatch(getProfile());
      dispatch(getInfo());
      history("/cockpit");
    } else {
      dispatch(apiError({ status: "errors", data: data }));
    }
  } catch (error) {
    // If any one of the requests fails it dispatches this "apiError" to show error message on screen
    dispatch(apiError({ status: "errors", data: error.data }));
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    const { success } = await LogoutAuth();

    if (success) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("authUser");
      dispatch(logoutUserSuccess(true));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

// export const socialLogin = (type, history) => async (dispatch) => {
//   try {
//     let response;

//     if ( window.env.REACT_APP_DEFAULTAUTH === "firebase") {
//       const fireBaseBackend = getFirebaseBackend();
//       response = fireBaseBackend.socialLoginUser(type);
//     }
//     //  else {
//     //   response = postSocialLogin(data);
//     // }

//     const socialdata = await response;
//     if (socialdata) {
//       localStorage.setItem("authUser", JSON.stringify(response));
//       dispatch(loginSuccess(response));
//       history("/dashboard");
//     }
//   } catch (error) {
//     dispatch(apiError(error));
//   }
// };

export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
