import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slices";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";

import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import client from "./infra/config/AxiosConfig";

const MyComponent = () => {
  const store = React.useMemo(
    () =>
      configureStore({
        reducer: rootReducer,
        devTools: true,
        middleware: (getDefaultMiddleware) =>
          getDefaultMiddleware({ serializableCheck: false }),
      }),
    []
  );

  client.setupInterceptors(store);

  return (
    <MantineProvider
      theme={{
        fontFamily: "Campton, sans-serif",
      }}
    >
      <PrimeReactProvider>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </PrimeReactProvider>
    </MantineProvider>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<MyComponent />);
