import axios from "axios";

// import AlertService from "../../shared/components/alert/AlertService";

const ConstructError = (response) => {
  if (typeof response?.data === "string" && response?.data !== "")
    return response.data;
  if (response?.data?.Message) return response.data.Message;
  if (response?.data?.message) return response.data.message;
  if (response?.data?.messages && response.data.messages.length > 0) {
    return response.data.messages[0];
  }
  return "The server sent a undefined error request";
};

// setup default client
const client = axios.create({
  baseURL: window.env.REACT_APP_API,
  // responseType: 'json'
});

// merge every request with default headers
const mergeCommonHeaders = (rest) => {
  const authToken = localStorage.getItem("authToken");
  return {
    ...rest,
    Authorization: `Bearer ${authToken}`,
  };
};
client.interceptors.request.use((request) => {
  request.headers = mergeCommonHeaders(request.headers);

  return request;
});

// intercept server response before sending to request function
client.setupInterceptors = () => {
  client.interceptors.response.use(
    (success) => {
      return {
        data: success.data,
        success: true,
      };
    },
    (error) => {
      if (error?.response?.status !== 406) {
        // TODO alert
        console.error(ConstructError(error?.response));
        // AlertService.error(
        //   "An error occurred",
        //   ConstructError(error?.response)
        // );
      }

      if (error && error.response && error.response.status === 401) {
        console.log(error);
        localStorage.clear();
        // eslint-disable-next-line no-restricted-globals
        // location.replace("/login");
      }

      return { ...error.response, success: false };
    }
  );
};

// client.setupInterceptors();

export default client;
